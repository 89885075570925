import idGenerator from "./idGenerator";

export default (url, id = undefined) => {
  const shortId = id || idGenerator();

  if (shortId && shortId.length > 8) {
    throw new Error("Short Ids must be less than 8 characters");
  }

  const alphaNumerics = /[^\w-+]/;
  if (shortId && alphaNumerics.test(shortId)) {
    throw new Error("Short Ids must be alpha numeric characters");
  }

  return {
    url,
    shortId,
  };
};
