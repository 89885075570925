import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import LinkForm from "../components/LinkForm"

import "bootstrap/dist/css/bootstrap.min.css"

const IndexPage = () => (
  <Container>
    <Row>
      <Col>
        <h1>James' Link Shortener</h1>
      </Col>
    </Row>

    <Row>
      <Col>
        <LinkForm />
      </Col>
    </Row>
  </Container>
)

export default IndexPage
