// stolen from https://tomspencer.dev/blog/2014/11/16/short-id-generation-in-javascript/

const ALPHABET =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

const ID_LENGTH = 8;

export default () => {
  const rtn = [];
  for (let i = 0; i < ID_LENGTH; i++) {
    rtn.push(ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length)));
  }
  return rtn.join("");
};
