import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import React, { useState } from "react";
import idGenerator from "../utils/idGenerator";
import validateShortLink from "../utils/validateShortLink";
import SuccessMessage from "./SuccessMessage";

function LinkForm() {
  const [id, setId] = useState("");
  const [url, setUrl] = useState("");
  const [isValidated, setValidated] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const shortenUrl = async (e) => {
    e.preventDefault();
    setSuccess(false);

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    setValidated(true);

    const linkObject = validateShortLink(url, id);

    await fetch(
      "https://wd89lk0en1.execute-api.eu-west-1.amazonaws.com/prod/create",
      {
        method: "POST",
        headers: {
          Origin: "https://jhd.link",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(linkObject),
      }
    )
      .then((res) => {
        if (res.status !== 201) {
          throw Error("Failed creating short link");
        }
        setSuccess(true);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Form noValidate validated={isValidated} onSubmit={(e) => shortenUrl(e)}>
      <Form.Group>
        <Form.Label>URL</Form.Label>
        <Form.Control
          type="url"
          pattern="https?://.*"
          required
          placeholder="https://www.example.com"
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid URL beginning http://
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Short Id</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon3">
              https://jhd.link/
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            id="shortId"
            value={id}
            required
            minLength="2"
            maxLength="8"
            onChange={(e) => setId(e.currentTarget.value)}
            aria-describedby="basic-addon3"
            width="10"
          />
          <InputGroup.Append>
            <Button
              onClick={() => setId(idGenerator())}
              variant="outline-secondary"
            >
              Generate Id
            </Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
            We need a shortId folks!
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Button variant="primary" type="submit">
        Save it!
      </Button>
      <SuccessMessage
        isDisplayed={isSuccess}
        shortLink={`https://jhd.link/${id}`}
      />
    </Form>
  );
}

export default LinkForm;
