import React from "react";
import { Alert } from "react-bootstrap";

export default ({ isDisplayed, shortLink }) => {
  return isDisplayed === false || !shortLink ? null : (
    <Alert variant="success" className="mt-3" data-testid="success-message">
      <Alert.Heading>ShortLink Created!</Alert.Heading>
      Visit your link: <Alert.Link href={shortLink}>{shortLink}</Alert.Link>
    </Alert>
  );
};
